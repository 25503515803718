<template>
  <div>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
      :hide-goto-end-buttons="true"
      :limit="1"
      class="pogination"
    >
      <template #prev-text>
        <img
          class="pagination__btn-icon pagination__btn-icon_prev"
          src="@/assets/png/database-icon-active.png"
          alt=""
        />
      </template>
      <template #next-text>
        <img class="pagination__btn-icon" src="@/assets/png/database-icon-active.png" alt="" />
      </template>
    </b-pagination>
    <div class="table">
      <b-table
        v-if="orderExport"
        id="my-table"
        class="table__box"
        :borderless="true"
        :items="orderExport"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        small
      >
        <template #cell(eta)="data">
          <p>{{ changeArr(data.item.from_date) }} - {{ changeArr(data.item.to_date) }}</p>
        </template>
        <template #cell(download)="data">
          <!--      @click="exportPost(data.item.id)"-->
          <a :href="$url + '/' + data.item.path" target="_blank" class="btn btn-primary">
            Скачать
          </a>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "employee.name",
          label: "Имя",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-lg",
        },
        {
          key: "eta",
          label: "Дата",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-lg",
        },
        {
          key: "download",
          label: "Скачать",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-lg",
        },
      ],
    };
  },
  methods: {
    changeArr(value) {
      if (value) {
        return value.split(" ")[0].split("-").join(".");
      }
      return value;
      // return value.split(' ')[0].split('-').join('.')
    },
    // exportPost (id) {
    //   this.$api.get(`/web/order-export?order_id=${id}`)
    //     .then(() => {
    //       this.$toast.success('Успешно!')
    //     })
    //     .catch(e => {
    //       this.$toast.error(e.response.data);
    //     })
    // }
  },
  computed: {
    rows() {
      if (this.orderExport) {
        this.$emit("spinner");
        return this.orderExport.length;
      }
      return 0;
    },
    ...mapState(["orderExport", "driversList"]),
  },
  created() {
    this.$store.dispatch("getOrderExport");
    this.$store.dispatch("getDriversList");
  },
};
</script>

<style scoped>
.pogination {
  position: absolute;
  top: -82px;
  right: 0;
}
.pagination__btn-icon_prev {
  transform: rotate(180deg);
}
</style>
